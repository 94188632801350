<template>
    <div class="form-cell">
        <div class="preview">
            <div class="row" v-for="(row,index) in data.rows" :key="index">
                <div class="col-label" >
                    <div v-for="(label,label_index) in row.labels" :key="label_index" :class="label_class(row,label_index)">
                        {{ label }}
                    </div>
                </div>
                <div class="col-input">
                    <div class="cell-wrap" v-for="(cell,cell_index) in row.items" :key="cell_index" :class="[cell.class,{ 'required': cell.required }]" :title="cell.title" >
                        
                        <div class="cell cell-label" v-if="cell.type === 'label'">{{ cell.content() }}</div>
                        <div class="cell cell-info" v-else-if="cell.type === 'info'">{{   }}</div>
                        <textarea class="cell cell-textarea" v-else-if="cell.type === 'textarea'" disabled/>
                        
                        <select v-else-if="cell.type === 'select'" class="cell cell-select">
                            <option class="option" v-for="(option,index) in cell.options()" :key="index" :value="option.code" :title="option.title" disabled>
                                {{ option.title }}
                            </option>
                        </select>
                        
                        <input 
                            class="cell cell-input" 
                            :id="getInputId(cell)" 
                            v-else-if="cell.type === 'checkbox'"
                            :type="cell.type" 
                            :name="cell.key" 
                            :aria-label="cell.title" 
                            disabled
                        />
                        <input 
                            class="cell cell-input" 
                            :id="getInputId(cell)" 
                            v-else  
                            :type="cell.type" 
                            :name="cell.key" 
                            :aria-label="cell.title" 
                            disabled
                        />
                        <label v-if="cell.label" :for="getInputId(cell)">{{ cell.label }}</label>
                    </div>
                </div>
            </div>
            <div class="buttons-block" v-for="(button,index) in data.buttons" :key="index">
                <BaseButton disabled :class="button.class" type="button">{{ button.text }}</BaseButton>
            </div>
        </div>
        <div class="edit-info-wrap">
            <div class="edit-info">{{ $t('controls.for_update_contact_support') }}</div>
        </div>
        
    </div>
</template>

<script>

export default {
    name: "FormCellAdmin",
    components: {
    },
    props: {
        data: Object
    },
    emits: [
        "change"
    ],
    setup(){        
        const label_class = (row,index) => {
            if(!row.labels_class || row.labels_class.length <= index){
                return "";
            }
            return row.labels_class[index];
        }      
        
        const getInputId = (cell) => {
            let key = cell.key.replace("[]", "");
            return 'input_'+key;
        };

        return {
            label_class,
            getInputId
        };
    }
}
</script>

<style scoped>
.preview{
    max-width: 30rem;
    margin: auto;
    transition: filter var(--transition-ease);
    filter: blur(0.1px);
}
.form-cell{
    position: relative;
    padding:0.5rem
}
.edit-info-wrap{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0;
    justify-content: center;
    font-size: 1.5rem;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgb(179 253 212 / 33%);
    transition: var(--transition-opacity);
}
.form-cell:hover .edit-info-wrap{
    opacity: 1;
}
.form-cell:hover .preview{
    filter: blur(5px);
}
 .cell-wrap{
        position: relative;
        margin-left: 0.5rem;
        /* height: 1.2rem; */
        width: 7rem;
    }
    .cell-wrap>label,.invalid-feedback{
        font-size: 0.8rem;
    }
    .cell{
        min-height: 1.2rem;
        width: 100%;
        height: 100%;
        display: block;
        line-height: 1.2rem;
    }
    .cell-info, .cell-label{
        font-size: 0.8rem;
        /* border: 1px dotted black;
        border: 1px dotted var(--dark-color); */
        white-space: nowrap;
        padding: 0 0.3rem;
    }
    .row{
        padding: 0.2rem;
    }
    
    /* .module-form select,.module-form input,.module-form .base-table-cell-info{
        width: 6rem;
    }
    .module-form .w-xs>*{
        width: 1rem;
    }
    .module-form .w-xl>*{
        width: 10rem;
    }
    .module-form .w-xxl>*{
        width: 15rem;
    } */

    .base-loader,.icon.error,.icon.orange_star{
        position: absolute;
        right: 0.1rem;
        top: 0.1rem;
        height: 1rem;
        width: 1rem;
        margin: 0;
    }
    .icon.orange_star{
        width: 0.7rem;
    }
    .required .cell-select {
        width: calc(100% - 1rem);
    }


    .header{
        padding: 0.5rem 1rem;
        background-color: var(--primary-color);
        margin: 0;
        box-shadow: 0px 1px 3px 1px grey;
        font-size: 1.5rem;
    }

    .cell-textarea {
        resize: vertical;
    }

.col-label {
    float: left;
    width: 30%;
    /* margin: 0.1rem 0; */
    font-size: 0.8rem;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
}
.col-label>div{
    flex: 1 1 0px;
}

.col-input {
    float: left;
    width: 70%;
    /* margin: 0.1rem 0; */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.col-input{
    display: flex;
    align-items: center;
}

.w-stretch{
    flex-grow: 2;
}
.w-xs{
    width: 3rem;
}
.w-s{
    width: 5rem;
}
.w-l{
    width: 10rem;
}
.w-xl{
    width: 15rem;
}
.w-fit{
    width: fit-content;
}
.h-2rows{
    height: 2.4rem;
}

.strong{
    font-weight: 700;
}

.form_small .form-rows{
    max-width: 30rem;
    margin: auto;
}

.buttons-block{
    margin-top: 2rem;
    display: flex;
    justify-content: space-evenly;
}
.buttons-block>button{
    margin: 0 0.5rem;
    min-width: 10rem;
    cursor: default;
    filter: grayscale(70%);
}
.btn .icon{
    margin: 0;
    width: 1.2rem;
    height: 1.2rem;
}
.buttons-block>button.delete-button{
    min-width: initial;
}
.accept-button{
    flex-grow: 1;
}

.file-area{
    position: relative;
    height: 10rem;
}

.cell-file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
  .col-label, .col-input, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
  .cell-file-input {
    padding: 5rem 2rem;
}
  
    .buttons-block>button{
        margin: 0 0.5rem;
        min-width: 30%;
    }
}


</style>